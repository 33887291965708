<template>
  <div class="">
    <div class="alert" :class="[getActive && 'active', getType === 'error' && 'alert-error', getType === 'warning' && 'alert-warning', getType === 'done' && 'alert-done', getType === 'info' && 'alert-info']">
      <div class="alert__wrapper">
        <div>
          <img src="@/assets/img/alerts/error.svg" alt="" class="alert-error__img" v-if="getType === 'error'">
          <span v-if="getType === 'error'">Ошибка</span>
          <img src="@/assets/img/alerts/warning.svg" alt="" class="alert-error__img" v-if="getType === 'warning'">
          <span v-if="getType === 'warning'">Осторожно</span>
          <img src="@/assets/img/alerts/done.svg" alt="" class="alert-error__img" v-if="getType === 'done'">
          <span v-if="getType === 'done'">{{ getText }}</span>
          <img src="@/assets/img/alerts/info.svg" alt="" class="alert-error__img" v-if="getType === 'info'">
          <span v-if="getType === 'info'">Информация</span>
        </div>
        <button class="alert__close" @click="closeAlert">
          <img src="@/assets/img/alerts/close.svg" alt="">
        </button>
      </div>
      <p class="alert__info" v-if="getType !== 'done'">{{ getText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alertComponent',
  methods: {
    closeAlert () {
      this.$store.dispatch('customAlert/closeAlert')
    }
  },
  computed: {
    getText () {
      return this.$store.getters['customAlert/getText']
    },
    getActive () {
      return this.$store.getters['customAlert/getActive']
    },
    getType () {
      return this.$store.getters['customAlert/getType']
    }
  }
}
</script>

<style scoped>

</style>
