<template>
  <div class="modal-recovery-1 modal-item">
    <div class="modal-title">Восстановление пароля</div>
    <div class="modal-input error">
      <p class="modal-input__recovery--text">Для восстановления пароля <br> введите свою почту или логин.</p>
      <p>E-mail</p>
      <input type="text" name="" id="" placeholder="Введите логин, e-mail или телефон">
    </div>
    <div class="modal-action">
      <button class="modal-action__primary" id="recovery-btn-1">Восстановить</button>
      <button class="modal-action__secondary" id="reg-2">Войти</button>
    </div>
  </div>
  <div class="modal-recovery-2 modal-item">
    <div class="modal-title">Восстановление пароля</div>
    <div class="modal-input error">
      <p>Новый пароль</p>
      <input type="text" name="" id="" placeholder="Новый пароль">
    </div>
    <div class="modal-input">
      <p>Подтвердите пароль</p>
      <input type="text" name="" id="" placeholder="Подтвердите пароль">
    </div>
    <div class="modal-switch">
      <p>Инструмент</p>
      <div class="modal-switch__wrapper">
        <div class="modal-switch__wrapper--item modal-switch__wrapper--item--3">
          <span>Панель администратора</span>
        </div>
        <div class="modal-switch__wrapper--item modal-switch__wrapper--item--4 active">
          <span>Чат-центр</span>
        </div>
      </div>
    </div>
    <div class="modal-action">
      <button class="modal-action__primary" id="recovery-btn-2">Войти</button>
    </div>
  </div>
  <div class="modal-success modal-item">
    <div class="modal-title">Успешно!</div>
    <p class="modal-success__text">Мы отправили Вам письмо для сброса пароля. <br> Если не нашли, проверьте папку
      «Спам».</p>
  </div>
</template>

<script>
export default {
  name: 'PassRecovery'
}
</script>

<style scoped>

</style>
