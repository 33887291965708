<template>
    <router-view></router-view>
    <alertComponent></alertComponent>
    <ConfirmPopup></ConfirmPopup>
</template>

<script>
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import alertComponent from '@/components/alertComponent'
import ConfirmPopup from '@/components/main/ConfirmPopup'

export default {
  name: 'App',
  components: {
    alertComponent,
    ConfirmPopup
  },
  methods: {
    getUserId () {
      return jwt_decode(localStorage.getItem('access_token')).id.user_id
    }
  },
  created () {
    if (!localStorage.getItem('access_token')) {
      this.$router.push('/login')
    }
  }
}
</script>
