const namespaced = true

const state = {
  bills: [{
    id: 1,
    payed: false,
    billName: 'Счет №1525 от 24.03.2020',
    summ: 72000,
    company: 'ООО системный интегратор',
    actLink: '1',
    actBpLink: '2',
    billLink: '3',
    billBpLink: '4'
  },
  {
    id: 2,
    payed: true,
    billName: 'Счет №1525 от 24.03.2020',
    summ: 72000,
    company: 'ООО системный интегратор',
    actLink: '2',
    actBpLink: '4',
    billLink: '6',
    billBpLink: '8'
  },
  {
    id: 3,
    payed: false,
    billName: 'Счет №1525 от 24.03.2020',
    summ: 72000,
    company: 'ООО системный интегратор',
    actLink: '1',
    actBpLink: '2',
    billLink: '3',
    billBpLink: '4'
  },
  {
    id: 4,
    payed: true,
    billName: 'Счет №1525 от 24.03.2020',
    summ: 72000,
    company: 'ООО системный интегратор',
    actLink: '2',
    actBpLink: '4',
    billLink: '6',
    billBpLink: '8'
  }]
}

const mutations = {
  DELETE_BILL (state, payload) {
    console.log(payload)
    state.bills = state.bills.filter(item => item.id !== payload)
  }
}

const actions = {
  deleteBill ({ commit }, billID) {
    commit('DELETE_BILL', billID)
    // return appClient.post('persons/deletePerson/', dataConfig).then((response) => {
    //   commit('DELETE_PERSON', dataConfig)
    //   return Promise.resolve(dataConfig)
    // })
  }
}

const getters = {
  bills: state => state.bills
}

const BillSettings = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default BillSettings
