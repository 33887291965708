import authService from '@/services/auth.service'
import axios from 'axios'
const user = localStorage.getItem('access_token')

const namespaced = true

const state = {
  currentUser: user ? { authorized: true, data: user } : { authorized: false, data: null },
  refresh: false
}

const mutations = {
  LOGIN_SUCCESS (state, user) {
    state.currentUser.authorized = true
    // state.currentUser = user
  },
  LOGIN_FAILED (state) {
    // state.currentUser.authorized = false
    state.currentUser = { authorized: false, data: null }
  },
  LOGOUT (state) {
    state.currentUser.authorized = { authorized: false, data: null }
  },
  REGISTER_SUCCESS (state) {
    state.currentUser.authorized = false
  },
  REGISTER_FAILED (state) {
    state.currentUser.authorized = false
  },
  UPDATE_TOKEN (state, payload) {
    localStorage.setItem('access_token', payload.access_token)
    localStorage.setItem('refresh_token', payload.refresh_token)
  }
}

const actions = {
  refresh ({ commit }) {
    axios.get('')
    commit('CHANGE_REFRESH')
  },
  logout ({ commit }) {
    commit('LOGOUT')
    return authService.logout()
  },
  login ({ commit }, user) {
    return authService.login(user).then(
      user => {
        commit('UPDATE_TOKEN', user)
        commit('LOGIN_SUCCESS', user)
        return Promise.resolve(user)
      },
      error => {
        commit('LOGIN_FAILED')
        return Promise.reject(error)
      }
    )
  },
  updateUser ({ commit }, payload) {
    commit('UPDATE_TOKEN', payload)
  }
}

const getters = {
  currentUser: state => state.currentUser,
  refreshToken: state => state.refresh_token,
  accessToken: state => state.access_token,
  getRefresh: state => state.refresh
}

const Auth = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default Auth
