const namespaced = true

const state = {
  cardList: [
    {
      id: 1,
      name: 'Название карты',
      number: '1234 5678 9101 1121',
      month: '12',
      year: '2023',
      cvv: '321'
    },
    {
      id: 2,
      name: 'Название карты',
      number: '1234 5678 9101 2222',
      month: '12',
      year: '2023',
      cvv: '321'
    },
    {
      id: 3,
      name: 'Название карты',
      number: '1234 5678 9101 333',
      month: '12',
      year: '2023',
      cvv: '321'
    }
  ],
  activeCard: null,
  selectedCard: null
}

const mutations = {
  SET_CARD_LIST (state, payload) {
    console.log(payload)
    state.cardList = payload
  },
  SET_ACTIVE_CARD (state, payload) {
    console.log(payload)
    state.activeCard = state.cardList.find(item => item.id === payload)
  },
  DELETE_CARD (state, payload) {
    console.log(payload)
    state.cardList = state.cardList.filter(item => item.id !== payload)
  },
  ADD_CARD (state, payload) {
    console.log(payload)
    state.cardList.push(payload)
  },
  SET_SELECTED_CARD (state, payload) {
    console.log(payload)
    state.selectedCard = payload
  },
  EDIT_CARD (state, payload) {
    console.log(payload)
    state.selectedCard = payload
  }
}

const actions = {
  getCardList ({ commit }, companyID) {
    commit('SET_CARD_LIST', [])
    // return appClient.get('persons/deletePerson/', dataConfig).then((response) => {
    //   commit('DELETE_PERSON', dataConfig)
    //   return Promise.resolve(dataConfig)
    // })
  },
  getActiveCard ({ commit }, companyID) {
    commit('SET_ACTIVE_CARD', {})
    // return appClient.get('persons/deletePerson/', dataConfig).then((response) => {
    //   commit('DELETE_PERSON', dataConfig)
    //   return Promise.resolve(dataConfig)
    // })
  },
  setActiveCard ({ commit }, { companyID, cardID }) {
    commit('SET_ACTIVE_CARD', cardID)
    // return appClient.get('persons/deletePerson/', dataConfig).then((response) => {
    //   commit('DELETE_PERSON', dataConfig)
    //   return Promise.resolve(dataConfig)
    // })
  },
  createCard ({ commit }, data) {
    commit('ADD_CARD', data)
    // return appClient.get('persons/deletePerson/', dataConfig).then((response) => {
    //   commit('DELETE_PERSON', dataConfig)
    //   return Promise.resolve(dataConfig)
    // })
  },
  getCard ({ commit }, cardID) {
    commit('SET_SELECTED_CARD', cardID)
    // return appClient.get('persons/deletePerson/', dataConfig).then((response) => {
    //   commit('DELETE_PERSON', dataConfig)
    //   return Promise.resolve(dataConfig)
    // })
  },
  editCard ({ commit }, cardID) {
    commit('EDIT_CARD', cardID)
    // return appClient.get('persons/deletePerson/', dataConfig).then((response) => {
    //   commit('DELETE_PERSON', dataConfig)
    //   return Promise.resolve(dataConfig)
    // })
  },
  deleteCardList ({ commit }, { companyID, cardID }) {
    commit('DELETE_CARD', cardID)
    // return appClient.get('persons/deletePerson/', dataConfig).then((response) => {
    //   commit('DELETE_PERSON', dataConfig)
    //   return Promise.resolve(dataConfig)
    // })
  }
}

const getters = {
  cards: state => state.cardList,
  actCard: state => state.activeCard,
  selectedCard: state => state.selectedCard
}

const CardSettings = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default CardSettings
