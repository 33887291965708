import { createRouter, createWebHistory } from 'vue-router'
import AuthorizationVue from '@/views/AuthorizationVue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/layouts/MainLayoutPage')
  },
  {
    path: '/add',
    children: [{
      path: 'company',
      name: 'company_add',
      component: () => import('@/layouts/company_add_layouts/MainLayout')
    }],
    component: () => import('@/views/CompanyAdd')
  },
  {
    path: '/login',
    name: 'login',
    component: AuthorizationVue
  },
  {
    path: '/settingsu',
    name: 'settings',
    component: () => import('../views/QuickSettings')
  },
  {
    path: '/settings',
    children: [{
      path: 'payment',
      children: [{
        path: 'method',
        component: () => import('@/layouts/payment_layout/PaymentHub')
      },
      {
        path: 'balance',
        component: () => import('@/layouts/payment_layout/ReplenishBalance.vue')
      },
      {
        path: 'new_bill',
        component: () => import('@/layouts/payment_layout/AddBill.vue')
      },
      {
        path: 'payment_success',
        component: () => import('@/layouts/payment_layout/PaymentSuccess.vue')
      },
      {
        path: 'payment_failed',
        component: () => import('@/layouts/payment_layout/PaymentFailed.vue')
      },
      {
        path: 'bills_list',
        component: () => import('@/layouts/payment_layout/BillList.vue')
      }
      ]
    }, {
      path: 'import',
      children: [{
        path: 'source',
        component: () => import('@/components/settings/import/sourseList')
      }, {
        path: 'import',
        component: () => import('@/components/settings/import/import')
      }, {
        path: 'export',
        component: () => import('@/components/settings/import/export')
      }],
      component: () => import('@/layouts/import_layouts/MainLayout')
    }, {
      path: 'editor',
      children: [{
        path: 'main',
        component: () => import('@/components/settings/editor/MainSettings')
      }, {
        path: 'lawyer',
        component: () => import('@/components/settings/editor/LawyerSettings')
      }, {
        path: 'tech',
        component: () => import('@/components/settings/editor/TechSettings')
      }],
      component: () => import('@/layouts/editor_layouts/MainLayout')
    },
    {
      path: 'filter',
      children: [{
        path: 'list',
        name: 'filter',
        component: () => import('@/components/settings/filter/FilterList')
      }, {
        path: 'add',
        name: 'add_filter',
        component: () => import('@/components/settings/filter/FilterAdd')
      }, {
        path: 'edit',
        name: 'edit_filter',
        component: () => import('@/components/settings/filter/FilterEdit')
      }],
      component: () => import('@/layouts/filter_layouts/MainLayout')
    },
    {
      path: 'auto',
      children: [{
        path: 'list',
        name: 'auto',
        component: () => import('@/components/settings/auto/AutoList')
      }, {
        path: 'add',
        name: 'add_auto',
        component: () => import('@/components/settings/auto/AutoAdd')
      }, {
        path: 'edit',
        name: 'edit_auto',
        component: () => import('@/components/settings/auto/AutoEdit')
      }],
      component: () => import('@/layouts/auto_layouts/MainLayout')
    },
    {
      path: 'users',
      children: [{
        path: 'inform',
        children: [{
          name: 'departments',
          path: 'department',
          component: () => import('../components/settings/users/user_tabs/UserOtdel')
        }, {
          name: 'members',
          path: 'members',
          component: () => import('../components/settings/users/user_tabs/Members')
        }],
        component: () => import('../components/settings/users/Users')
      },
      {
        path: 'add',
        children: [{
          path: 'department',
          component: () => import('../components/settings/users/users_components_layouts/departmentAddComponent')
        }, {
          path: 'user',
          component: () => import('../components/settings/users/users_components_layouts/userAddComponent')
        }],
        component: () => import('@/layouts/users/AddMainUserLayout')
      },
      {
        path: 'edit',
        children: [{
          name: 'edit_department',
          path: 'department',
          component: () => import('../components/settings/users/users_components_layouts/DepartmentEditCompanent')
        }, {
          name: 'edit_user',
          path: 'user',
          component: () => import('../components/settings/users/users_components_layouts/UserEditComponent')
        }],
        component: () => import('@/layouts/users/AddMainUserLayout')
      }],
      component: () => import('@/layouts/users/MainUsersLayout')
    },
    {
      path: 'mailing',
      children: [{
        path: 'add',
        component: () => import('../layouts/mailing_layouts/MailingAdd')
      },
      {
        path: 'info',
        component: () => import('../components/settings/mailing/MailingHub')
      },
      {
        path: 'edit',
        name: 'edit_mailing',
        component: () => import('../layouts/mailing_layouts/MailingEdit')
      }],
      component: () => import('../layouts/mailing_layouts/Mailing')
    },
    {
      path: 'channels',
      children: [{
        path: 'add',
        name: 'add',
        component: () => import('../components/settings/canals/AddCanal').then(m => m.default)
      },
      {
        path: 'edit',
        name: 'edit',
        component: () => import('../components/settings/canals/EditCanal')
      },
      {
        path: 'addType',
        name: 'addType',
        component: () => import('../components/settings/canals/AddCanalType')
      },
      {
        path: 'info',
        children: [{
          path: 'active',
          component: () => import('../components/settings/canals/canals_tabs/activeCanals')
        },
        {
          path: 'disable',
          component: () => import('../components/settings/canals/canals_tabs/disableCanals')
        }
        ],
        component: () => import('../layouts/canals_layouts/CanalsList')
      }],
      component: () => import('../layouts/canals_layouts/MainLayout')
    },
    {
      path: 'crm',
      children: [{
        path: 'add',
        component: () => import('../components/settings/crm/AddToStoplist')
      },
      {
        path: 'editsl',
        name: 'editStopList',
        component: () => import('../components/settings/crm/EditStopList')
      },
      {
        path: 'user',
        name: 'userinfo',
        component: () => import('../components/settings/crm/user/MainPage')
      },
      {
        path: 'info',
        children: [{
          path: 'list',
          name: 'clientlist',
          component: () => import('../components/settings/crm/crm_tabs/list')
        },
        {
          path: 'segments',
          name: 'segments',
          component: () => import('../components/settings/crm/crm_tabs/SegmentsListMain')
        },
        {
          path: 'stoplist',
          name: 'stoplist',
          component: () => import('../components/settings/crm/crm_tabs/stoplist')
        }
        ],
        component: () => import('../layouts/crm_layouts/crmClientsList')
      }],
      component: () => import('../layouts/crm_layouts/MainLayout')
    },
    {
      path: 'ads',
      children: [
        {
          path: 'info',
          component: () => import('../components/settings/adv/Info')
        },
        {
          path: 'add',
          children: [
            {
              path: 'main',
              component: () => import('../components/settings/adv/advAddTabs/main')
            },
            {
              path: 'analitic',
              component: () => import('../components/settings/adv/advAddTabs/analitic')
            }
          ],
          component: () => import('../components/settings/adv/AddAdv')
        },
        {
          path: 'adv_edit',
          name: 'adv_edit',
          component: () => import('../components/settings/adv/EditAdv.vue'),
          children: [{
            path: 'main',
            component: () => import('../components/settings/adv/advEditTabs/main')
          },
          {
            path: 'analitic',
            component: () => import('../components/settings/adv/advEditTabs/analitic')
          }]
        }],
      component: () => import('../layouts/adv_layouts/MainLayout')
    }
      // {
      //   path: 'templates',
      //   children: [{
      //     path: 'list',
      //     component: () => import('../layouts/templates_layouts/TemplateList')
      //   }, {
      //     path: 'add',
      //     component: () => import('../layouts/templates_layouts/TemplateAdd')
      //   }],
      //   component: () => import('../layouts/templates_layouts/MainLayout')
      // }
    ],
    component: () => import('../views/settingUser')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters.isAuthenticated) {
//       next()
//       return
//     }
//     next('/login')
//   } else {
//     next()
//   }
// })

export default router
