<template>
  <div class="modal-reg modal-item">
    <div class="modal-title">Регистрация</div>
    <div class="modal-input error">
      <p>Название компании</p>
      <input type="text" name="" id="" placeholder="Название компании">
    </div>
    <div class="modal-input">
      <p>Как вас зовут</p>
      <input type="text" name="" id="" placeholder="Андрей Владимирович Котов">
    </div>
    <div class="modal-phone">
      <p>Телефон</p>
      <div class="modal-phone__wrapper">
        <div class="modal-phone__wrapper--btn">
          <img src="img/icon-ru.svg" alt="">
          <img src="img/icon-arrow.svg" alt="" class="modal-phone__wrapper--btn--icon">
        </div>
        <input type="text" class="modal-phone__wrapper--input" placeholder="7">
        <div class="modal-phone__wrapper--list">
          <button class="modal-phone__wrapper--list--item">
            <img src="img/icon-ru.svg" alt="">
            <span>+12</span>
            <p>Country name</p>
          </button>
          <button class="modal-phone__wrapper--list--item">
            <img src="img/icon-ru.svg" alt="">
            <span>+12</span>
            <p>Country name</p>
          </button>
          <button class="modal-phone__wrapper--list--item">
            <img src="img/icon-ru.svg" alt="">
            <span>+12</span>
            <p>Country name</p>
          </button>
          <button class="modal-phone__wrapper--list--item">
            <img src="img/icon-ru.svg" alt="">
            <span>+12</span>
            <p>Country name</p>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-action">
      <button class="modal-action__primary">Зарегистрироваться</button>
      <button class="modal-action__secondary" id="login">Войти</button>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Reg'
}
</script>

<style scoped>

</style>
