const namespaced = true

const state = {
  type: '',
  active: false,
  text: ''
}

const mutations = {
  OPEN_ALERT (state, payload) {
    state.type = payload.type
    state.active = true
    state.text = payload.text
    setTimeout(() => {
      state.active = false
    }, 2000)
  },
  CLOSE_ALERT (state, payload) {
    state.active = false
  }
}

const actions = {
  openAlert ({ commit }, payload) {
    commit('OPEN_ALERT', payload)
  },
  closeAlert ({ commit }) {
    commit('CLOSE_ALERT')
  }
}

const getters = {
  getActive: state => state.active,
  getText: state => state.text,
  getType: state => state.type
}

const customAlert = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default customAlert
