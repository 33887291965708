import appClient from '@/services/http.service'

const namespaced = true

const state = {
  departments: [],
  users: []
}

const mutations = {
  UPDATE_ALL_DEPARTMENTS (state, payload) {
    state.departments = payload
  },
  UPDATE_ALL_USERS (state, payload) {
    state.users = payload
  }
}

const actions = {
  getDepartments ({ commit }, companyId) {
    appClient.get('departments/all/' + companyId).then((response) => {
      commit('UPDATE_ALL_DEPARTMENTS', response.data.response.data)
      return Promise.resolve(response.data.response.data)
    })
  },
  getUsers ({ commit }, companyId) {
    appClient.get('employees/all/' + companyId).then((response) => {
      commit('UPDATE_ALL_USERS', response.data.response.data)
      return Promise.resolve(response.data.response.data)
    })
  },
  addDepartment ({ commit }, data) {
    appClient.post('departments/add/', data).then((response) => {
      actions.getDepartments(data.id)
    }).catch((response) => {
      return false
    })
  },
  addMember ({ commit }, data) {
    appClient.post('employees/add/', data).then((response) => {
      actions.getDepartments(data.id)
      return Promise.resolve(response.data.response.data)
    }).catch((err) => {
      return Promise.resolve(err)
    })
  }
}

const getters = {
  departments: state => state.departments,
  users: state => state.users
}

const UserSettings = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default UserSettings
