import axios from 'axios'
import store from '@/store'
import router from '@/router'

const authTokens = localStorage.getItem('access_token') || null
const baseURL = 'https://dmitriy.dev.intellectdialog.com/api/app/' // code
// const baseURL = 'https://connect.intellectdialog.com/api/app/' // code
// const baseURL = 'http://162.55.134.176:4099/api/app/'
const appClient = axios.create({
  baseURL,
  headers: {
    Authorization: authTokens
  }
})

appClient.interceptors.request.use(req => {
  req.headers.Authorization = localStorage.getItem('access_token')
  return req
})

appClient.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const rs = await refreshToken()
          store.dispatch('auth/updateUser', rs.data.response.data)
          console.log(rs.data.response.data)
          return appClient(originalConfig)
        } catch (_error) {
          console.log('sdfjksdaoewsjowdesik')
          localStorage.clear()
          router.push('login')
          location.reload()
          if (_error.response && _error.response.data) {
            console.log('sdfjksdaoewsjowdesik')
            return Promise.reject(_error.response.data)
          }

          return Promise.reject(_error)
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data)
      }
    }

    return Promise.reject(err)
  }
)

function refreshToken () {
  return appClient.post('oauth/', {
    grand_by: 'refresh_token',
    refresh_token: localStorage.getItem('refresh_token')
  })
}

export default appClient
