const namespaced = true

const state = {
  active: false,
  method: Function,
  data: ''
}

const mutations = {
  OPEN_CONFIRMER (state, payload) {
    state.active = true
    state.method = payload[0]
    state.data = payload[1]
  },
  CLOSE_CONFIRMER (state) {
    state.active = false
    state.method = ''
  },
  START_METHOD (state) {
    state.method(state.data)
  }
}

const actions = {
  openAlert ({ commit }, payload) {
    commit('OPEN_CONFIRMER', payload)
  },
  closeAlert ({ commit }) {
    commit('CLOSE_CONFIRMER')
  },
  confirmMethod ({ commit }) {
    commit('START_METHOD')
    commit('CLOSE_CONFIRMER')
  }
}

const getters = {
  getActive: state => state.active
}

const Confirmer = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default Confirmer
