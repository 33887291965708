<template>
  <Form @submit="handleLogin" :validation-schema="schema" class="modal-login modal-item">
    <div class="modal-title">Вход</div>
    <div v-if="message" class="alert alert-danger" role="alert">
      {{ message }}
    </div>
    <MainSwitcher></MainSwitcher>
    <div class="modal-input error">
      <p>Логин, E-mail или телефон</p>
      <Field type="text" name="login" placeholder="Введите логин, e-mail или телефон"/>
      <ErrorMessage name="login" class="error-feedback"/>
    </div>
    <div class="modal-input">
      <p>Пароль</p>
      <Field name="password" type="password" placeholder="Введите пароль"/>
      <button class="modal-action__recovery" id="recovery">Забыли пароль?</button>
    </div>
    <div class="modal-action">
      <button type="submit" class="modal-action__primary">Войти</button>
      <button class="modal-action__secondary" id="reg-1">Регистрация</button>
    </div>
  </Form>
</template>

<script>

import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import MainSwitcher from '@/components/authorization/switcher'

export default {
  name: 'LogIn',
  components: {
    Form, Field, ErrorMessage, MainSwitcher
  },
  data () {
    const schema = yup.object().shape({
      login: yup.string().required('Обязательное поле!'),
      password: yup.string().required('Пароль обязательно!')
    })
    return {
      loading: false,
      message: '',
      schema
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.currentUser.authorized.authorized
    }
  },
  created () {
    if (this.loggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    handleLogin (user) {
      this.loading = true
      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/')
        },
        (error) => {
          this.loading = false
          this.message = (error.responce && error.responce.data && error.responce.data.message) || error.message || error.toString()
        }
      )
    },
    getUserId () {
      return jwt_decode(localStorage.getItem('access_token')).id.user_id
    }
  }
}
</script>
