import appClient from '@/services/http.service'

const namespaced = true

const state = {
  filters: [],
  filter_from_id: {}
}

const mutations = {
  UPDATE_ALL_FILTERS (state, payload) {
    state.filters = payload
  },
  UPDATE_FILTER (state, payload) {
    state.filter_from_id = payload
  }
}

const actions = {
  getFilters ({ commit }, companyId) {
    appClient.get('filters/all/' + companyId).then((response) => {
      commit('UPDATE_ALL_FILTERS', response.data.response.data)
      console.log(response.data.response.data)
      return Promise.resolve(response.data.response.data)
    })
  },
  getFilterById ({ commit }, filterId) {
    appClient.get('filters/get/' + filterId).then((response) => {
      commit('UPDATE_FILTER', response.data.response.data)
      console.log(response.data.response.data)
      return Promise.resolve(response.data.response.data)
    })
  }
}

const getters = {
  filterlist: state => state.filters,
  filter: state => state.filter_from_id
}

const FiltersModule = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default FiltersModule
