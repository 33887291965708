<template>
  <div class="modal-top">
    <a href="#!" class="modal-top__back">
      <img src="img/icon-back.svg" alt="">
      <span>Назад</span>
    </a>
    <a href="#!" class="modal-top__logo">
      <img src="img/modal-logo.svg" alt="">
    </a>
    <div class="modal-top__lang">
      <div class="modal-top__lang--wrapper">
        <img src="img/icon-ru.svg" alt="">
        <span>RU</span>
        <img src="img/icon-arrow.svg" alt="" class="modal-top__lang--wrapper--icon">
      </div>
      <div class="modal-top__lang--list">
        <a href="#!" class="modal-top__lang--list--item">
          <img src="img/icon-en.svg" alt="">
          <span>EN</span>
        </a>
        <a href="#!" class="modal-top__lang--list--item">
          <img src="img/icon-en.svg" alt="">
          <span>EN</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthHeader'
}
</script>

<style scoped>

</style>
