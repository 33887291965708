import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

const app = createApp(App)

// app.config.globalProperties.APP_URL = 'https://connect.intellectdialog.com/api/app/'
app.config.globalProperties.APP_URL = 'https://dmitriy.dev.intellectdialog.com/api/app/'

app.use(i18n).use(store).use(router).mount('#app')
