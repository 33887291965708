<template>
  <div class="modal-switch">
    <p>Инструмент</p>
    <div class="modal-switch__wrapper" @click="changeActive()">
      <div class="modal-switch__wrapper--item modal-switch__wrapper--item--1" :class="[mode && 'active']">
        <span>Панель администратора</span>
      </div>
      <div class="modal-switch__wrapper--item modal-switch__wrapper--item--2" :class="[!mode && 'active']">
        <span>Чат-центр</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSwitcher',
  data () {
    return {
      mode: true
    }
  },
  methods: {
    changeActive () {
      this.mode = !this.mode
    }
  }
}
</script>

<style scoped>

</style>
