import appClient from '@/services/http.service'

const namespaced = true

const state = {
  mailing_list: []
}

const mutations = {
  UPDATE_MAILINGLIST (state, payload) {
    state.mailing_list = payload
  }
}

const actions = {
  getMailings ({ commit }, data) {
    return appClient.get('distributions/all/' + data.companyId + `?page=${data.page}`).then((response) => {
      commit('UPDATE_MAILINGLIST', response.data.response.data)
      // console.log(response.data.response.count)
      return Promise.resolve(response.data.response)
    })
  }
}

const getters = {
  mailinglist: state => state.mailing_list
}

const MailingModule = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default MailingModule
