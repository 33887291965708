import appClient from '@/services/http.service'

const namespaced = true

const state = {
  stop_list: [],
  person_list: [],
  person_list_segments: []
}

const mutations = {
  UPDATE_STOPLIST (state, payload) {
    state.stop_list = payload
  },
  UPDATE_PERSON_LIST (state, payload) {
    state.person_list = payload
  },
  DELETE_PERSON (state, payload) {
    console.log(payload)
    state.person_list = state.person_list.filter(item => item.id !== payload.people_id)
  },
  DELETE_FROM_STOP_LIST (state, payload) {
    state.stop_list = state.stop_list.filter(item => item.stoplist_id !== payload)
  },
  UPDATE_PERSON_LIST_SEGMENTS (state, payload) {
    state.person_list_segments = payload
  }
}

const actions = {
  getStopList ({ commit }, dataConfig) {
    return appClient.get(`crm/stop_list/${localStorage.getItem('active_company')}/?page=${dataConfig.page ? dataConfig.page : 1}&sort_field=${dataConfig.sort_field ? dataConfig.sort_field : 'created_at'}&sort_type=${dataConfig.sort_type ? dataConfig.sort_type : 'desc'}&search=${dataConfig.search ? dataConfig.search : ''}`).then((response) => {
      commit('UPDATE_STOPLIST', response.data.response.data)
      console.log(response.data.response.data)
      return Promise.resolve(response.data.response.count)
    })
  },
  getPersonList ({ commit }, dataConfig) {
    const urlWithParams = `crm/persons_list/${localStorage.getItem('active_company')}/?page=${dataConfig.page ? dataConfig.page : 1}&sort_field=${dataConfig.sort_field ? dataConfig.sort_field : ''}&sort_type=${dataConfig.sort_type ? dataConfig.sort_type : ''}&search=${dataConfig.search ? dataConfig.search : ''}`
    return appClient.get(urlWithParams).then((response) => {
      commit('UPDATE_PERSON_LIST', response.data.response.data)
      console.log(response.data.response.data)
      return Promise.resolve(response.data.response.count)
    }).catch(() => {
      commit('UPDATE_PERSON_LIST', [])
    })
  },
  deletePerson ({ commit }, dataConfig) {
    return appClient.post('persons/deletePerson/', dataConfig).then((response) => {
      commit('DELETE_PERSON', dataConfig)
      return Promise.resolve(dataConfig)
    })
  },
  deleteFromStopList ({ commit }, dataConfig) {
    return appClient.post(`crm/stop_list_delete/${dataConfig}`).then((response) => {
      commit('DELETE_FROM_STOP_LIST', dataConfig)
      return Promise.resolve(dataConfig)
    })
  },
  getPersonListViaSegments ({ commit }, dataConfig) {
    const urlWithParams = `/segments/get_segment_peoples/${dataConfig.segment_id}?page=${dataConfig.page ? dataConfig.page : 1}&search=${dataConfig.search ? dataConfig.search : ''}`
    return appClient.get(urlWithParams).then((response) => {
      commit('UPDATE_PERSON_LIST_SEGMENTS', response.data.response.data)
      console.log(response.data.response.data)
      return Promise.resolve(response.data.response.count)
    }).catch(() => {
      commit('UPDATE_PERSON_LIST_SEGMENTS', [])
    })
  }
}

const getters = {
  stopList: state => state.stop_list,
  personList: state => state.person_list,
  personListsSegments: state => state.person_list_segments
}

const CrmModule = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default CrmModule
