import appClient from '@/services/http.service'

const namespaced = true

const state = {
  integration_list: [],
  integration_type_list: []
}

const mutations = {
  UPDATE_ALL_INTEGRATIONS (state, payload) {
    state.integration_list = payload
  },
  UPDATE_INTEGRATION_TYPE (state, payload) {
    state.integration_type_list = payload
  },
  DELETE_INTEGRATIONS (state, payload) {
    state.integration_list = state.integration_list.filter(item => item.id !== payload.providerId)
  }
}

const actions = {
  getIntegrations ({ commit }, companyId) {
    return appClient.get('channels/all/' + companyId).then((response) => {
      commit('UPDATE_ALL_INTEGRATIONS', response.data.response.data)
      return Promise.resolve(response.data.response.data)
    })
  },
  deleteIntegration ({ commit }, integData) {
    return appClient.post('providers/delete/', integData).then((response) => {
      commit('DELETE_INTEGRATIONS', integData)
      return Promise.resolve(integData)
    })
  },

  deletePerson ({ commit }, dataConfig) {
    return appClient.post('persons/deletePerson/', dataConfig).then((response) => {
      commit('DELETE_PERSON', dataConfig)
      return Promise.resolve(dataConfig)
    })
  },

  getProviderTypes ({ commit }) {
    return appClient.get('ProviderTypes/all/').then((response) => {
      commit('UPDATE_INTEGRATION_TYPE', response.data.response.data)
      return Promise.resolve(response.data.response.data)
    })
  }
}

const getters = {
  integrations: state => state.integration_list,
  integration_types: state => state.integration_type_list
}

const IntegrationModule = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default IntegrationModule
