import appClient from '@/services/http.service'

const namespaced = true

const state = {
  companyAllItems: [],
  searchedCompanies: [],
  companyItem: [],
  activeCompany: {},
  activeCompanyId: localStorage.getItem('active_company') || ''
}

const mutations = {
  UPDATE_ALL_COMPANIES (state, payload) {
    state.companyAllItems = payload
    state.activeCompany = payload[0]
  },
  UPDATE_COMPANY (state, payload) {
    state.companyItem = payload
  },
  UPDATE_ACTIVE_COMPANY (state, payload) {
    state.activeCompany = payload
    localStorage.setItem('active_company', payload.id)
    state.activeCompanyId = payload.id
  },
  FIRST_UPDATE_ACTIVE_COMPANY (state, payload) {
    if (localStorage.getItem('active_company') === '') {
      state.activeCompany = payload
      localStorage.setItem('active_company', payload.id)
      state.activeCompanyId = payload.id
    }
  },
  UPDATE_COMPANY_LIST (state, payload) {
    if (payload) {
      state.searchedCompanies = state.companyAllItems.filter(item => item.name.includes(payload))
    } else {
      state.searchedCompanies = state.companyAllItems
    }
  }
}

const actions = {
  getCompaniesAll ({ commit }) {
    appClient.get('/companies/all').then((response) => {
      commit('UPDATE_ALL_COMPANIES', response.data.response.data)
      return Promise.resolve(response.data.response.data)
    })
  },
  getCompanyById ({ commit }, companyId) {
    appClient.get('/companies/get', companyId).then((response) => {
      commit('UPDATE_COMPANY', response.data.data)
    })
  },
  getUserCompanies ({ commit }, userId) {
    return appClient.get('/companies/get_by_user/' + userId).then((response) => {
      commit('UPDATE_ALL_COMPANIES', response.data.response.data)
      commit('UPDATE_COMPANY_LIST', '')
      commit('FIRST_UPDATE_ACTIVE_COMPANY', response.data.response.data[0])
      return Promise.resolve(response.data.response.data)
    })
  },
  updateActiveCompany ({ commit }, company) {
    commit('UPDATE_ACTIVE_COMPANY', company)
  }
}

const getters = {
  companyAllItems: state => state.companyAllItems,
  searchedCompany: state => state.searchedCompanies,
  activeCompany: state => state.activeCompany,
  GetActiveCompanyId: state => state.activeCompanyId
}

const Company = {
  namespaced,
  state,
  mutations,
  actions,
  getters
}

export default Company
