import appClient from '@/services/http.service'

class AuthService {
  async login (user) {
    const response = await appClient
      .post('oauth/', {
        grand_by: 'password',
        login: user.login,
        password: user.password
      })
    if (response.data) {
      console.log('Login responce: ' + response)
      // localStorage.setItem('accessTokens', JSON.stringify(response.data.response.data))
    }
    return response.data.response.data
  }

  logout () {
    localStorage.clear()
  }

  register (user) {
    return appClient.post('/auth/register', {
      company_name: user.company_name,
      name: user.name,
      phone: user.phone,
      login: user.login,
      email: user.email,
      password: user.password,
      password_confirm: user.password_confirm
    })
  }
}
export default new AuthService()
