<template>
  <div class="wrapper">
    <div class="modal modal-auth active status-2">
      <AuthHeader></AuthHeader>
      <LogIn></LogIn>
      <PassRecovery></PassRecovery>
      <Reg></Reg>
    </div>
  </div>
</template>

<script>
import AuthHeader from '@/components/authorization/AuthHeader'
import LogIn from '@/components/authorization/LogIn'
import PassRecovery from '@/components/authorization/PassRecovery'
import Reg from '@/components/authorization/Reg'

export default {
  name: 'AuthorizationVue',
  components: {
    AuthHeader,
    LogIn,
    PassRecovery,
    Reg
  }
}
</script>

<style scoped>

</style>
