<template>
  <div class="modal-exit" :class="[getActive && 'active']">
    <div class="modal-exit__wrapper">
      <div class="modal-exit__wrapper--header">
        <span>Предупреждение</span>
        <div class="modal-exit__wrapper--header--close" @click="close()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#383838"/>
          </svg>
        </div>
      </div>
      <p>Вы действительно хотите удалить объект?</p>
      <div class="modal-exit__wrapper--action">
        <div class="modal-exit__wrapper--action--back modal-exit__wrapper--action--btn" @click="close()">
          <span>Отмена</span>
        </div>
        <div class="modal-exit__wrapper--action--exit modal-exit__wrapper--action--btn" @click="confirm()">
          <span>Да</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmPopup',
  methods: {
    close () {
      this.$store.dispatch('Confirmer/closeAlert')
    },
    confirm () {
      this.$store.dispatch('Confirmer/confirmMethod')
    }
  },
  computed: {
    getActive () {
      return this.$store.getters['Confirmer/getActive']
    }
  }
}
</script>

<style scoped>

</style>
