import { createStore, createLogger } from 'vuex'
import Company from './modules/CompanyModule'
import auth from './modules/auth'
import UserSettings from '@/store/modules/settings/UsersModule'
import IntegrationModule from '@/store/modules/settings/IntegrationModule'
import FiltersModule from '@/store/modules/settings/FiltersModule'
import CrmModule from '@/store/modules/settings/CrmModule'
import MailingModule from '@/store/modules/settings/MailingModule'
import customAlert from '@/store/modules/notification'
import Confirmer from '@/store/modules/confirmer'
import BillSettings from '@/store/modules/settings/BillModul'
import CardSettings from '@/store/modules/settings/CardModul'

const debug = true

const store = createStore({
  modules: {
    Company,
    auth,
    UserSettings,
    IntegrationModule,
    FiltersModule,
    CrmModule,
    MailingModule,
    customAlert,
    Confirmer,
    BillSettings,
    CardSettings
  },
  plugins: debug ? [createLogger()] : []
})

export default store
